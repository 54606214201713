.floatingCart {
    padding: 0px !important;
    color: #000000;

    h4 {
        color: #333;
        font-size: 12px;
        padding: 5px 15px;
    }

    .totalPriceCart {
        color: #9d0000;
        font-size: 1.2rem;
        font-weight: bold;
    }

    .productsWrapper {
        border-radius: 0;
        border-top: 1px solid #c7c7c7;
        border-bottom: 1px solid #c7c7c7;
        max-height: 260px;
        overflow-y: scroll;
        padding: 5px;
    }

    .productRow {
        display: flex;
        align-items: center;
        text-align: center;
        background-color: #ffffff;
        margin-top: 15px;

        .productName {
            font-size: 12px;
            line-height: 14px;
            float: left;
            text-align: left;
        }

        .productPrice,
        .productQuantity {
            text-align: right;
            font-size: 11px;
            font-weight: 500;
        }

        .productQuantity {
            color: #9d0000;
        }
    }

    .productIcon {
        width: 40px !important;
    }

    .priceWrapper {
        background-color: #ded9d3;
        padding: 10px;
        margin-top: 10px;
        font-size: 14px;
        font-weight: 500;

        .currency {
            color: #9d0000;
        }
    }

    .finalizeButton {
        display: block;
        border: none;
        width: 100%;
        border-radius: 0.3rem;
        padding: 13px 25px;
        background-color: #008c00;
        font-weight: 500;
        font-size: 13px;

        a {
            color: #fff;
        }

        &:hover {
            background-color: #008c00;
        }

        &:disabled {
            cursor: not-allowed;
            background-color: #008c00;
        }
    }

    .copyCart {
        display: inline-flex;
        margin-left: 3px;

        svg {
            font-size: 12px;
        }
    }
}
