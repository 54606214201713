.container {
    margin-top: 10rem;

    .titleInfo {
        font-weight: 600;
        font-size: 5rem;
    }

    .subTitleInfo {
        font-weight: 600;
        font-size: 2rem;
    }

    .shortInfo {
        margin-top: 1.7rem;
        line-height: 1.8rem;
    }

    .infoService {
        background-color: #333;
        color: #fff;
        padding: 1rem 2rem;
        border-radius: 5rem;
        width: auto;
        display: inline-block;
        margin-top: 2rem;
    }
}
