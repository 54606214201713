.topNavbarWrapper {
    background-color: var(--secondary-background-color);
    color: #333;
    border-bottom: 1px solid rgba(var(--rgb-primary-color), 0.42);
    box-shadow: 0px 5px 9px -4px rgba(0, 0, 0, 0.25);

    &.sticky {
        animation: slide-down 0.5s;
        top: 0;
        z-index: 5;
        position: sticky;

        .productMenuContent {
            margin-top: 18px !important;
        }
    }

    @keyframes slide-down {
        0% {
            transform: translateY(-100%);
        }
        100% {
            transform: translateY(0);
        }
    }

    .domainName {
        color: var(--background-header-th);
    }

    .searchContainer {
        padding: 8px 0px 4px;
    }

    .search {
        border-radius: 30px;
        box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.3);
    }

    .cartButton {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0;
        border-radius: 0;
        padding: 1rem;
        position: relative;
        margin-right: -5px;
        padding-right: 5px;
        cursor: pointer;

        .additionalComponent {
            width: 385px;
            background-color: #fff;
            border-radius: 0.3rem;
            z-index: 999;
            position: absolute;
            right: 1px;
            top: calc(100%);
            cursor: default;
            animation: growDown 300ms ease-in-out forwards;
            transform-origin: top center;
            box-shadow: 0px 3px 9px 2px rgba(0, 0, 0, 0.25);
        }
    }

    @media (max-width: 767.98px) {
        .logoTopNav {
            width: 100%;
            height: auto;
        }
    }
}

.menuWrapper {
    background-color: var(--primary-background-color);
    position: relative;
    color: #ffffff;
    box-shadow: 0px 5px 9px -4px rgba(0, 0, 0, 0.25);

    .menu {
        padding: 0;

        [class="nav-link"] {
            a {
                color: #fff;
                padding: 0.95rem 1.5rem 0.85rem 1.5rem !important;

                &:hover {
                    color: var(--primary-color);
                }
            }
        }
    }

    .productMenuButton {
        display: flex;
        align-items: center;
        border: 0;
        border-radius: 0;
        background: none;
        padding: 1.4rem 2rem;
        font-weight: bold;
        background-color: var(--primary-color);

        &:after {
            display: none;
        }
        // &:hover,
        // .show {
        //     background: none;
        // }
    }

    .productMenuContent {
        width: 340px;
        position: absolute;
        border-radius: 0;
        border: none;
        box-shadow: 0 0 1px 1px hsla(0, 0%, 61.2%, 0.2);
        padding: 0;
        margin-top: 0 !important;

        .menuIcon {
            margin: 0;
            margin-right: 10px;
        }

        .productMenuEntry {
            display: flex;
            align-items: center;
            height: 52px;
            cursor: pointer;
            font-size: 14px;
            padding: 5px 25px;
            background: #fff;
        }
    }

    .contact {
        background-color: var(--primary-color);
        border-radius: 30px;
        box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.3);
        font-weight: 500;
        margin-right: 1.3rem;
        border: none;
        padding: 0.5rem 0.6rem;
    }
}

.mobileNavBar {
    padding: 0 0.8rem;
    svg {
        font-size: 1.2rem;
    }
}

.itemMenuMobile {
    padding: 0.9rem 1.5rem;
    border-bottom: 1px solid #ddd;
    font-weight: bold;
    font-size: 0.85rem;
    display: block;
    color: #000;
    background-color: var(--secondary-background-color);
}

.mobileOffCanvas {
    background-color: #fff !important;

    .mobileWrapperMenu {
        border-top: 2px solid var(--primary-color);
        border-bottom: 2px solid var(--primary-color);
    }

    .secondWrapperMenu {
        margin-top: 1rem;
        .itemMenuMobile {
            font-size: 1.1rem;
            background-color: #fff;
            font-weight: bold;
            border: none;
            padding: 0.6rem 1rem;
        }
    }
}

.dropdownMenuProd {
    button {
        width: 100%;
    }

    .productMenuContent {
        width: 300px;
        border-radius: 0;
        border: none;
        padding: 0;
        margin-top: 0 !important;
        box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.3);

        .productMenuEntry {
            display: flex;
            align-items: center;
            height: 52px;
            cursor: pointer;
            font-size: 14px;
            border-top: 1px solid hsla(0, 2%, 80%, 0.42);
            padding: 5px 25px;
            background: #fff;

            &:hover {
                background-color: rgba(var(--rgb-primary-color), 0.1);
            }
        }
    }
}

@keyframes growDown {
    0% {
        transform: scaleY(0);
    }
    80% {
        transform: scaleY(1.1);
    }
    100% {
        transform: scaleY(1);
    }
}
