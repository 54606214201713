.searchBar {
    position: relative;
    transition: background-color 0.3s ease;
    flex: 1;
    display: block;
    margin: 0 auto;

    input {
        background-color: #fff;
        min-height: 42px;

        &:focus {
            border: none;
        }

        border-radius: 3rem;
    }
}

.inputText {
    border-right: none !important;
    background-color: #fff !important;
    border-top-left-radius: 3rem !important;
    border-bottom-left-radius: 3rem !important;
    z-index: 6;
    svg {
        font-size: 1.2rem;
        margin-left: 0.4rem;
    }
}

.inputHoverEffect {
    border-radius: 3rem;
    box-shadow: 0px 0px 12px 1px rgb(223 111 0 / 85%);
    -webkit-box-shadow: 0px 0px 12px 1px rgb(223 111 0 / 85%);
    -moz-box-shadow: 0px 0px 12px 1px rgb(223 111 0 / 85%);
}

.searchboxBack {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    z-index: 51;
    visibility: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition:
        opacity 0.3s,
        visibility 0.3s;
    transition:
        opacity 0.3s,
        visibility 0.3s;
    background-color: rgba(0, 0, 0, 0.5);
    overflow-y: hidden;

    &.active {
        opacity: 1;
        visibility: visible;
        z-index: 2;
    }
}

.searchResult {
    z-index: 4;
    position: absolute;
    padding: 4rem 0.5rem 1.2rem;
    top: 0px;
    width: 100%;
    background-color: #fff;
    border-radius: 1.3rem;
    color: #333;

    @media (max-width: 767.98px) {
        left: 0;
    }

    .searchItem {
        padding-right: 1.2rem;
        margin: 0.5rem 0;
        color: #333;

        img {
            padding: 0.2rem;
            margin-right: 0.5rem;
            max-width: 60px;
        }

        .productName {
            font-size: 0.8rem;
            font-weight: 500;
            color: #333;
        }

        .productReference {
            font-size: 0.7rem;
            color: #333;
        }

        .productCategory {
            margin-top: 0.3rem;
            color: #333;
            font-size: 0.8rem;
        }

        .productStock {
            font-size: 0.85rem;
            padding-left: 0.7rem;
        }

        .productPrice {
            color: #d10c0c;
            font-size: 0.85rem;
            font-weight: 600;
            margin-left: auto;
        }
    }

    .headerSearch {
        font-size: 0.8rem;
        font-weight: 500;
        color: var(--primary-color);
        margin-bottom: 1rem;
    }

    .searchItemCategory {
        font-size: 0.83rem;
        margin-bottom: 0.3rem;
        color: #333;
        display: block;

        &:hover {
            color: var(--primary-color);
        }
        .productCount {
            color: #333;
            margin-left: 0.34rem;
            font-weight: 500;
        }
    }
}
