.footer {
    background-color: var(--primary-background-color);
    color: #ffffff;
}
.footerAccordion {
    background-color: var(--primary-background-color);
    color: #ffffff;
}

.footerMobileImages {
    align-items: center;
    bottom: 10px;
    display: flex;
    justify-content: center;
    position: fixed;
    width: auto;
    left: 40px;
}

.accordionBody {
    background-color: var(--primary-background-color);
    color: #ffffff;
}

.footerInfo {
    font-size: 0.82rem;
}

.accordionHeader {
    button {
        background-color: var(--primary-background-color);
        box-shadow: none;

        &:focus {
            box-shadow: none;
        }
        &[aria-expanded="true"] {
            background-color: var(--primary-color);
            color: #ffffff;
        }

        &[aria-expanded="false"] {
            background-color: var(--primary-background-color);
            color: #ffffff;
        }
        &::after {
            filter: invert(1);
        }
    }
}

.footerSection {
    & > div {
        padding-top: 1.5rem !important;

        @media (max-width: 767.98px) {
            text-align: center;
        }
    }
}

.footerSocialLinks {
    display: flex;
    margin-top: 2rem !important;

    a {
        background-color: #fff;
        color: #000;
        border-radius: 50%;
        font-size: 22px;
        padding: 0.1rem;
        width: 40px;
        text-align: center;
        height: 40px;
        margin-right: 1rem;
    }

    @media (max-width: 767.98px) {
        margin-top: 1rem !important;
    }
}

.footerLink a,
.footerTitle {
    display: inline-block;
    margin-bottom: 4px;
    font-size: 13px;
    font-weight: 500;
    padding: 12px 0 0 0;
    width: 100%;
    text-decoration: none;
    color: #fff;
}

.footerTitle {
    font-size: 18px;
    margin-bottom: 30px;
}

.companyInfo {
    list-style-type: none;
    padding: 0;

    li {
        display: flex;
        align-items: flex-start;
        padding: 0.6rem 0;
        color: #c1c1c1;
        font-size: 0.87rem;

        a {
            color: #c1c1c1;

            &:hover {
                color: var(--primary-color);
            }
        }

        @media (max-width: 767.98px) {
            justify-content: center;
        }

        svg {
            margin-right: 0.7rem;
            display: block;
        }
    }
}

.copyright {
    font-size: 14px;
    line-height: 1.71;
    color: #fff;
    margin: 2rem 0;
    text-align: center;

    @media (min-width: 992px) {
        text-align: left;
    }
}
